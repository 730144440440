<template>

  <VueHeadful
    :title="$store.getters['pageHead/getTitle']"
    :description="$store.getters['pageHead/getDescription']"/>

</template>

<script>
  import VueHeadful from "vue-headful";

  export default
  {
    name: "PageHeadHandler",

    components:
    {
      VueHeadful
    }
  }
</script>

<style scoped>

</style>